import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { indexArticle } from '../../../../store/actions/article';
import { content } from '../../content.js';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, CardContent, CardActionArea } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    root: {
        'flex-grow': 1,
        'padding': '50px 50px',
        'background-color': theme.palette.primary.main,
        'border-top': '1px solid #73cec0',
    },
    [theme.breakpoints.down('xs')]: {
        root: {
            'padding': '25px 0px',
        },
        card: {
            'margin': '20px'
        }
    },
    container: {
        'padding': '0px 10px'
    },
    card: {
        'background-color': theme.palette.primary.light,
        'padding': '10px 10px',
    },
    cardAction: {
        'height': '100%',
        minHeight: '330px'
    },
    subheader: {
        'color': '#73cec0',
        'font-style': 'italic'
    },
}));

const LatestArticles = ({ config }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mainAuthorId = "60cce970906dd783d3f0b15b";

    const articles = useSelector((state) => state.article.articles[mainAuthorId] || []);

    useEffect(() => {
        if (articles.length === 0) {
            dispatch(indexArticle(mainAuthorId));
        }
    }, [dispatch, mainAuthorId, articles.length]);

    const sortedArticles = [...articles].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const latestArticles = sortedArticles.slice(0, 6);

    const { language } = config;
    const sectionContent = content.updates[language];

    return (
        <div className={classes.root}>
            <Grid id="articles" container spacing={4} justify="left">
                <Grid item style={{ textAlign: 'center' }} xs={12}>
                    <Hidden xsDown>
                        <Typography gutterBottom variant="h2" color='textPrimary'>
                            {sectionContent?.heading}
                        </Typography>
                        <Typography className={classes.subheader} variant="h4">
                            {sectionContent?.subheading}
                        </Typography>
                    </Hidden>
                    <Hidden smUp>
                        <Typography gutterBottom variant="h4" color='textPrimary'>
                            {sectionContent?.heading}
                        </Typography>
                        <Typography className={classes.subheader} variant="h6">
                            {sectionContent?.subheading}
                        </Typography>
                    </Hidden>
                </Grid>
                {latestArticles.map((article) => (
                    <Grid item key={article._id} xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardActionArea className={classes.cardAction} component={Link} to={`/articles?id=${article._id}`}>
                                {/* If you have an image, include CardMedia */}
                                {/* <CardMedia
                                    className={classes.media}
                                    image={article.image || 'default-image.jpg'}
                                    title={article.title}
                                /> */}
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {article.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {article.createdAt
                                            ? new Date(article.createdAt).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })
                                            : 'No date available'
                                        }
                                    </Typography>
                                    <Typography variant="body1" component="p">
                                        {article.longDescription}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default LatestArticles;
